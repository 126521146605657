import React from "react";
import {connect} from "react-redux";
import ToolCard from "../../components/ToolCard";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import tools from "../../helpers/tools.json";
import {Icon} from "@iconify/react";

const popularTools = [
    tools[0].tools[0],
    tools[0].tools[1],
    tools[0].tools[2],
    tools[0].tools[3],
    tools[0].tools[4],
    tools[1].tools[0],
    tools[1].tools[1],
    tools[1].tools[2],
    tools[1].tools[3],
    tools[2].tools[0],
    tools[2].tools[1],
    tools[2].tools[2],
    tools[3].tools[0],
    tools[3].tools[1],
    tools[3].tools[2],
    tools[3].tools[3],
]

const DashboardPage = ({auth}) => {
    const linkTo = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="dashboard_new">
                <div className="dashboard_new_left">
                    <span className="dashboard_new_left_text_top"><span style={{
                        color:"black",
                        fontWeight:"700"
                    }}>{auth.user.name} —</span> {t("Let's make something remarkable today!")}</span><br />
                    <span className="text_b_dash">You have done <strong style={{color:"black"}}>68%</strong> 😎 more sales today.<br />
Check your new badge in your profile.</span>
                </div>
                <div className="dashboard_new_right">
                    <img src="/assets/images/dash_user.svg"/>
                </div>
            </div>
            <div className="row mt-4 g-lg-4 g-2">
                {[
                    {
                        title: t('Words Generated'),
                        icon: 'mdi:user-outline',
                        backIcon: "#969aff57",
                        colorIcon: "#666CFF",
                        description: `${auth.user.words_generated}`
                    },
                    {
                        title: t('Items Generated'),
                        icon: 'material-symbols:grouped-bar-chart',
                        backIcon: "#ffcc6836",
                        colorIcon: "#FDB528",
                        description: `${auth.user.items_generated + ' ' + t('items')}`
                    },
                    {
                        title: t('Time Saved'),
                        icon: 'ic:outline-show-chart',
                        backIcon: "#5dd6fd4d",
                        colorIcon: "#26C6F9",
                        description: `${(auth.user.words_generated/500).toFixed(0) + ' ' + t('hours')}`
                    },
                    {
                        title: t('Tools Used'),
                        icon: 'mdi:dollar',
                        backIcon: "#b6ff857a",
                        colorIcon: "#72E128",
                        description: `${auth.user.tools_used}/56`
                    }
                ].map((item, index) => (
                    <div key={index} className="col-lg-3 col-6">
                        <div className="h-100 card p-3 dash_card">
                            <div className="dash_card_left">
                                <div style={{
                                    background: item.backIcon,
                                    color: item.colorIcon,
                                }} className="dash_card_icon">
                                    <Icon width="32" height="26" icon={item.icon}/>
                                </div>
                            </div>
                            <div className="dash_card_right">
                                <p className="text-muted mb-1" style={{
                                    "fontWeight": 600,
                                    "fontSize": "larger",
                                    "color": "#4C4E64"
                                }}>{item.description}</p>
                                <p className="fw-bold mb-0">{item.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-4 d-flex justify-content-between align-items-center">
                <div>
                    <h2 className="fw-bolder">{t('Most Popular Tools')}</h2>
                    <p className="mb-0">{t('These are the widely used and highly regarded tools. Give them a shot and see for yourself!')}</p>
                </div>
                <button
                    onClick={(e) => {
                        linkTo('/dashboard/tools');
                        e.preventDefault();
                    }}
                    className="btn btn_to_bot">{t('All Tools')}</button>
            </div>
            <div className="row mt-4 tools-list g-lg-4 g-3">
                {popularTools.map((item, index) => <ToolCard item={item} key={index}/>)}
            </div>
        </>
    )
}

export default connect(({auth}) => ({auth}))(DashboardPage);
