import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {Icon} from "@iconify/react";
import {Dropdown} from "react-bootstrap";
import {store} from "../../store/configureStore";
import {hexToRgbA} from "../../helpers/Utils";
import {useTranslation} from "react-i18next";
import tools from "../../helpers/tools.json";
import toolsDocTop from "../../helpers/toolsDocTop.json";
import UserAvatar from "../../components/UserAvatar";
import SubscribeBar from "../../components/SubscribeBar";
import i18n from "i18next";
import Modal from 'react-modal';
import ToolCard from "../../components/ToolCard";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LanguagesForm from "../../components/LanguagesForm";
import Button from "react-bootstrap/Button";
import API from "../../helpers/Axios";
import {Base64} from "js-base64";
import CopyButton from "../../components/CopyButton";
import EditorDock from "./helpers/EditorDock";
import EditorDockId from "./helpers/EditorDockId";
import ChatPage from "../dashboard/ChatPage";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '70%',
    },
};



const AccountToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn border-0 p-0 d-flex align-items-center bg-link"
    >
        {children}
    </button>
));

const CreateContentToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn btn-outline-primary d-flex align-items-center"
    >
        {children}
    </button>
));

function searchArrayByToolTitle(array, searchTerm) {
    return array.map(item => ({
        ...item,
        tools: item.tools.filter(tool => tool.title.toLowerCase().includes(searchTerm.toLowerCase()))
    })).filter(item => item.tools.length > 0);
}

const UserIndexPageDoc = ({auth, display, common}) => {
    const { t } = useTranslation();
    const linkTo = useNavigate();
    const projectModalRef = useRef(null);
    const [isSideBarShown, setIsSideBarShown] = useState(false);
    const [engineTools, setEngineTools] = useState(tools);
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [td, titleDock] = useState("Article Composer");
    const [iIcon, iconInst] = useState("material-symbols:article-outline");
    const [iIconColor, iconInstColor] = useState("#71e128");
    const [dDoc,descriptionDoc] = useState("Создайте всеобъемлющий, удобный для SEO пост в блоге на основе заголовка и схемы, используя технологию искусственного интеллекта.");
    const [validated, setValidated] = useState(false);
    const [processes, setProcesses] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [variants, setVariants] = useState(2);
    const [variantsContent, setVariantsContent] = useState([]);
    const [savedIndexes, setSavedIndexes] = useState([]);
    const { slug } = useParams();
    const [textAreaValue, setTextAreaValue] = useState('');
    const [savedContents, setSavedContents] = useState([]);
    const [deletingId, setDeletingId] = useState(null);
    const [editor, setEditor] = useState(null);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = new FormData(event.target);
            setProcesses(Array.from({length: variants}, (_, i) => i));
            runProcess(Array.from({length: variants}, (_, i) => i), data);
            setVariantsContent(Array.from({length: variants}, (_, i) => ''));
            setSavedIndexes([]);
            setTabIndexSaved(0);
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const [pathName, setPathName] = useState('');

    const runProcess = async (processes, data) => {
        if (processes.length === 0) {
            setIsRunning(false);
            return;
        }

        let builtString = '';

        for (const [name, value] of data.entries()) {
            console.log(name, value);
            if(name !== 'language') {
                builtString += name + '=' + value + ' & ';
            }
        }

        setIsRunning(true);
        const currentProcess = processes[0];
        try {
            await new Promise(resolve => {
                API.post('auth/user/text-generator', {
                    action: Base64.encode(`write ${pathName} with data --> ${builtString.replace(/_/g, ' ')}`)
                }).then((res) => {
                    if(res.data && res.data.usage) {
                        store.dispatch({type: 'UPDATE_TOKENS', tokens: store.getState().common.tokens - res.data.usage});
                    }
                    setVariantsContent((prev) => {
                        const newArray = [...prev];
                        newArray[currentProcess] = res.data.text.replace(/^\s+|\s+$/g, '').split("\n").map(paragraph => `<p>${paragraph}</p>`).join('');
                        return newArray;
                    });
                    resolve(true);
                }).catch (error => {
                    resolve(true);

                });
            });
        } catch (error) {
            console.error(`Error running process: ${currentProcess}`);
        }
        setProcesses(processes.slice(1));
        await runProcess(processes.slice(1), data);
    };


    const switchLanguage = (lang) => {
        fetch(`/assets/lang/${lang}.json`)
            .then((response) => response.json())
            .then((data) => {
                i18n.addResourceBundle(lang, 'translation', data);
            }).then(() => {
            i18n.changeLanguage(lang);
            store.dispatch({type: 'SWITCH_LANGUAGE', lang: lang});
        });
    }

    const [tabIndexSaved, setTabIndexSaved] = useState(
        JSON.parse(
            '0',
        ) || 0
    );

    const [tabIndex, setTabIndex] = useState(
        JSON.parse(
            '0',
        ) || 0
    );


    const [tabIndexInst, setTabIndexInst] = useState(
        JSON.parse(
            '0',
        ) || 0
    );


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [value, setValue] = useState('');
    const handleChange = (event) => {
        setValue(event)
    }

    function changeInst(item){
        console.log(item);
        titleDock(item.title);
        iconInst(item.icon_name);
        iconInstColor(item.icon_color);
        descriptionDoc(item.description);
        variantsContent.length = null;
        savedContents.length  = null;
        getSavedContents(pathName);
    }

    function updateText(text) {
        React.cloneElement(text, '1', EditorDock);
    }


    const ToolCardInst = ({item}) => {

        const { t } = useTranslation();

        return (
            <div className="col-lg-3 col-12">
                <a className="text-decoration-none link-dark"
                   onClick={(e) => {
                       changeInst(item);
                       setPathName(item.alt_name);
                       handleChange([{ method: 'type', text: item.alt_name }]);
                       closeModal();
                   }}

                >
                    <div className="h-100 card p-3">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="card-tool-icon" style={{backgroundColor: hexToRgbA(item.icon_color)}}>
                                <Icon icon={item.icon_name} style={{color: item.icon_color}} width="24" />
                            </div>
                            <Icon icon="material-symbols:arrow-outward-rounded" className="text-muted" />
                        </div>
                        <p className="fw-bold mb-1">{t(item.title)}</p>
                        <p className="mb-0 text-secondary">{t(item.description)}</p>
                    </div>
                </a>
            </div>
        )
    }
    const saveContent = (text, index) => {
        API.post('auth/content/save', {
            type: pathName,
            content: text
        }).then(res => {
            setSavedIndexes([...savedIndexes, index])
        }).catch (error => {

        });
        getSavedContents(pathName);
    }

    const getSavedContents = (type) => {
        API.post('auth/contents', {
            type: type,
        }).then(res => {
            setSavedContents(res.data.data)
        }).catch (error => {

        });
    }

    const deleteContent = (id, index) => {
        setSavedContents((prev) => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
        ]);
        API.post('auth/content/delete', {
            id: deletingId,
        }).then(res => {

        }).catch (error => {

        });
    }


    return (
        <>
            <div className="w-100 min-vh-100 bg-light">
                <header className="dashboard-header w-100 position-sticky border-bottom bg-light">
                    <SubscribeBar/>
                    <div className="w-100 px-lg-5 px-3 py-3">
                        <div className="d-flex flex-md-row align-items-center justify-content-between">
                            <button
                                onClick={() => {
                                    setIsSideBarShown(true)
                                }}
                                className="d-lg-none d-block border-0 bg-transparent">
                                <Icon icon="charm:menu-hamburger" width="32" className="text-secondary"/>
                            </button>
                            <div className="d-lg-flex d-none align-items-center">
                                <a
                                    onClick={(e) => {
                                        linkTo(`/dashboard`);
                                        e.preventDefault();
                                    }}
                                    href={`/dashboard`}
                                    className="d-flex align-items-center text-decoration-none text-dark"
                                >
                                    <img width="120" height="40" src="/assets/images/Logo232.svg"/>
                                </a>
                                <nav className="d-lg-inline-flex d-none ms-5 gap-4">
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/dashboard/plans`);
                                            e.preventDefault();
                                        }}
                                        href={`/dashboard/plans`}
                                        className="me-3 py-2 text-dark text-decoration-none">{t('Plans & Pricing')}</a>
                                </nav>
                            </div>
                            <nav className="d-flex align-content-center gap-3">

                                <Dropdown
                                    className="ms-2"
                                >
                                    <Dropdown.Toggle
                                        className="btn-outline-primary bg-transparent border-0"
                                    >
                                        <img width="24" height="24" className="rounded" src={`/assets/flags/${display.language}.svg`}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {[
                                            {
                                                name: 'English',
                                                code: 'en',
                                            },
                                            {
                                                name: 'Русский',
                                                code: 'ru',
                                            }
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    switchLanguage(item.code)
                                                }}
                                                key={index}
                                                className="d-flex align-items-center py-2"
                                            >
                                                <img width="24" height="24" className="rounded" src={`/assets/flags/${item.code}.svg`} alt={item.name}/>
                                                <small className="ms-2">{item.name}</small>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown align="end"
                                          onClick={(e) => {
                                              linkTo(`/dashboard/tools`);
                                              e.preventDefault();
                                          }}
                                          href={`/dashboard/tools`}>
                                    <Dropdown.Toggle itemID="instIndex" as={CreateContentToggle}>
                                        <Icon icon="mdi:plus-box-outline" width="24"/>
                                        <span className="ms-2 d-lg-block d-none">{t('Create Content')}</span>
                                    </Dropdown.Toggle>

                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle as={AccountToggle}>
                                        <div className="rounded-circle overflow-hidden">
                                            <UserAvatar
                                                name={auth.user.name}
                                                url={auth.user.artwork_url}
                                                width="38"
                                            />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="d-flex align-items-center px-3 py-2">
                                            <div className="me-2 rounded-circle overflow-hidden">
                                                <UserAvatar
                                                    name={auth.user.name}
                                                    url={auth.user.artwork_url}
                                                    width="38"
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-dark fw-bold">{auth.user.name}</small>
                                                <div className="d-flex align-items-center">
                                                    <Icon icon="material-symbols:token-outline" width="20" className="text-warning"/>
                                                    <span className="text-secondary fw-bold ms-1">{common.tokens}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-1"/>
                                        {[
                                            {
                                                iconName: 'eos-icons:package-upgrade-outlined',
                                                title: t('Upgrade Account'),
                                                link: '/dashboard/plans'
                                            },
                                            {
                                                iconName: 'material-symbols:credit-card-outline',
                                                title: t('Billing & Plan'),
                                                link: '/dashboard/billing'
                                            },
                                            {
                                                iconName: 'carbon:settings',
                                                title:  t('Settings'),
                                                link: '/dashboard/settings'
                                            },
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                onClick={(e) => {
                                                    linkTo(item.link);
                                                    e.preventDefault();
                                                }}
                                                href={item.link}
                                            >
                                                <div className="d-flex align-items-center my-1">
                                                    <Icon icon={item.iconName} width="24"/>
                                                    <span className="ms-3">{item.title}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        <hr className="my-1"/>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                linkTo(`/`);
                                                store.dispatch({type: 'UPDATE_ACCESS_TOKEN', accessToken: ''});
                                                store.dispatch({type: 'TOGGLE_AUTH', user: {}});
                                                e.preventDefault();
                                            }}
                                            href="/sign-out"
                                        >
                                            <div className="d-flex align-items-center my-1">
                                                <Icon icon="uil:signout" width="24"/>
                                                <span className="ms-3">{t('Sign out')}</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </nav>
                        </div>
                    </div>
                </header>
                <main className="dashboard-main px-lg-5 px-3 pb-5">
                    <div className="d-flex flex-nowrap mobile-page-dock">
                        <div className={`docmainsidebar pt-lg-4 ${isSideBarShown && 'm-shown'}`}>
                            <div className="h-100 block-dock">

                                <div className="d-flex top-doc-buttom">
                                    {toolsDocTop.map((item, index) => (

                                        <div
                                            onClick={() => setTabIndex(index)}
                                            className={`doc-button bg-white ${tabIndex === index && 'used-button'}`}
                                            aria-current="page"
                                        >
                                                <span
                                                    className={'doc-button-text'}
                                                >{item.title} </span>
                                        </div>

                                    ))}
                                </div>

                                {tabIndex === 0 && (
                                    <div className="body-select-items">
                                    <div
                                        onClick={openModal}
                                        className="group flex w-full items-center justify-between rounded-xl bg-white px-3 py-2 cursor-pointer hover:bg-gray-50 doc-tools-select">
                                        <div className="block-inst">
                                            <div className="mt-1">
                                                <div className="doc-text-left"><strong>{t(td)}</strong></div>
                                                <div className="doc-text-desk">
                                                    {t(dDoc)}
                                                </div>
                                            </div>
                                            <div className="card-tool-icon" style={{backgroundColor: hexToRgbA(iIconColor)}}>
                                                <div>
                                                    <Icon icon={iIcon} style={{color: iIconColor}} width="24" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-neutral-700 group-hover:text-neutral-900"><span
                                            className="i-material-symbols:grid-view-rounded block"></span></div>
                                    </div>

                                    <Modal
                                        isOpen={modalIsOpen}
                                        onAfterOpen={afterOpenModal}
                                        onRequestClose={closeModal}
                                        style={customStyles}
                                        contentLabel="Modal"
                                    >
                                        <div className="d-flex align-items-center">
                                            <h1>{t('All tools')}</h1>
                                        </div>
                                        <ul className="nav nav-tabs nav-tabs-none-bg mt-3">
                                            {tools.map((item, index) => (
                                                <li
                                                    key={index}
                                                    className="nav-item"
                                                >
                                                    <button
                                                        onClick={() => setTabIndexInst(index)}
                                                        className={`nav-link text-dark d-flex align-items-center ${tabIndexInst === index && 'active'}`}
                                                        aria-current="page"
                                                    >
                                                        <span>{item.title} </span>
                                                        <span className="ms-2 badge rounded-pill fw-light">{item.tools.length}</span></button>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="row mt-4 tools-list g-lg-4 g-3">
                                            {tools[tabIndexInst].tools.map((item, index) =>
                                                <ToolCardInst
                                                    item={item} key={index}/>)}
                                        </div>
                                    </Modal>
                                </div> )}
                                {tabIndex === 0 && (
                                <Form className="doc-tools-form bg-white" noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label>Language</Form.Label>
                                            <LanguagesForm />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Creativity</Form.Label>
                                            <Form.Select name="creativity">
                                                <option value="high">High</option>
                                                <option value="regular">Regular</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    {(pathName === 'blog-section-author' || pathName === 'paragraph-writer-for-blogs') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Title of your blog article</Form.Label>
                                                <Form.Control
                                                    name="blog_title"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('5 ways to boost your sales with copywriting')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Subheading / Bullet Point</Form.Label>
                                                <Form.Control
                                                    name="subheading"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Identify Your Target Audience and Speak to Their Needs"
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'blog-introduction-maker' || pathName === 'blog-outline-tool') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Title of your blog article</Form.Label>
                                                <Form.Control
                                                    name="article_title"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="5 ways to boost your sales with copywriting"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>What is your blog post about?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="post_content"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="a blog article about the best tools to increase your website traffic"
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'blog-conclusion-writer') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Article title or topic</Form.Label>
                                                <Form.Control
                                                    name="topic"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="5 ways to boost your sales with copywriting"
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'paragraph-creator') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>{t('What is your paragraph about?')}</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/1200</small>
                                                </div>
                                                <Form.Control
                                                    name="paragraph_content"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('The best cryptocurrency to invest in')}
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Keyword to include')}</Form.Label>
                                                <Form.Control
                                                    name="include_keyword"
                                                    required
                                                    placeholder="Cryptocurrencies"
                                                    rows="4"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Tone of voice')}</Form.Label>
                                                <Form.Select name="tone_of_voice">
                                                    <option value="professional">Professional</option>
                                                    <option value="childish">Childish</option>
                                                    <option value="luxurious">Luxurious</option>
                                                    <option value="friendly">Friendly</option>
                                                    <option value="confident">Confident</option>
                                                    <option value="exciting">Exciting</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'engaging-blog-titles' || pathName === 'blog-inspiration-generator' || pathName === 'listicles-blog-titles') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>{t('What is your blog post about?')}</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="post_content"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="a blog article about the best tools to increase your website traffic"
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'talk-points-generator') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Article title')}</Form.Label>
                                                <Form.Control
                                                    name="article_title"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('5 ways to boost your sales with copywriting')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Subheading')}</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('Why you should use an AI writer')}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {['pros-cons-maker', 'generate-startup-names'].includes(pathName) && (
                                        <>
                                            {pathName === 'pros-cons-maker' && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('Product Name')}</Form.Label>
                                                    <Form.Control
                                                        name="product_name"
                                                        required
                                                        placeholder={t('Product Name')}
                                                        rows="4"
                                                    />
                                                </Form.Group>
                                            )}
                                            {pathName === 'generate-startup-names' && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t('Seed Words')}</Form.Label>
                                                    <Form.Control
                                                        name="seed_words"
                                                        required
                                                        placeholder="e.g. fit, flow..."
                                                        rows="4"
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>{t('Product Description?')}</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/1200</small>
                                                </div>
                                                <Form.Control
                                                    name="product_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Explain here to the AI what your product (or service) is about. Rewrite to get different results."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    )}

                                    {(['web-page-headlines', 'subtitles-for-websites', 'frequently-asked-questions', 'answers-for-faqs', 'pain-stimulate-solution', 'user-testimonials-reviews', 'how-your-product-works', 'our-story', 'call-to-action-phrases', 'facebook-ad-content', 'facebook-ad-headline-writing', 'google-ads-headline-creation', 'google-ads-description-writing', 'create-product-descriptions', 'craft-unique-value-proposition', 'turn-features-into-benefits', 'write-welcome-emails', 'cancellation-email', 'write-email-subject-lines', 'generate-confirmation-emails']).includes(pathName) && (
                                        <>
                                            {!(['answers-for-faqs', 'frequently-asked-questions', 'pain-stimulate-solution', 'user-testimonials-reviews', 'how-your-product-works', 'call-to-action-phrases', 'facebook-ad-content', 'facebook-ad-headline-writing', 'google-ads-headline-creation', 'create-product-descriptions']).includes(pathName) && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tone of voice</Form.Label>
                                                    <Form.Select
                                                        name="tone_of_voice"
                                                    >
                                                        <option value="professional">Professional</option>
                                                        <option value="childish">Childish</option>
                                                        <option value="luxurious">Luxurious</option>
                                                        <option value="friendly">Friendly</option>
                                                        <option value="confident">Confident</option>
                                                        <option value="exciting">Exciting</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            )}
                                            <Row className="mb-3">
                                                {(pathName !== 'user-testimonials-reviews') && (
                                                    <Form.Group as={Col}>
                                                        <Form.Label>{t('Audience')}</Form.Label>
                                                        <Form.Control
                                                            name="audience"
                                                            required
                                                            type="text"
                                                            placeholder="Freelancers, Designers,..."
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group as={Col} controlId="validationCustom02">
                                                    <Form.Label>Product Name</Form.Label>
                                                    <Form.Control
                                                        name="product_name"
                                                        required
                                                        type="text"
                                                        placeholder="Netflix, Spotify, Uber..."
                                                    />
                                                </Form.Group>
                                            </Row>
                                            {(pathName === 'generate-confirmation-emails') && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Confirmation of</Form.Label>
                                                    <Form.Control
                                                        name="confirmation_of"
                                                        required
                                                        type="text"
                                                        placeholder="Sale, Sign-up,..."
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Product Description?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/400</small>
                                                </div>
                                                <Form.Control
                                                    name="product_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Explain here to the AI what your product (or service) is about. Rewrite to get different results."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                            {(pathName === 'answers-for-faqs') && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>What is the question you are generating answers for?</Form.Label>
                                                    <Form.Control
                                                        name="answers_for"
                                                        required
                                                        type="text"
                                                        placeholder="Your question here"
                                                    />
                                                </Form.Group>
                                            )}
                                        </>
                                    )}

                                    {(['business-social-media-post', 'define-company-mission', 'write-vision-statement', 'write-press-releases']).includes(pathName) && (
                                        <>
                                            <Row className="mb-3">
                                                {(pathName !== 'user-testimonials-reviews') && (
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Company Name</Form.Label>
                                                        <Form.Control
                                                            name="company_name"
                                                            required
                                                            type="text"
                                                            placeholder="Amazon"
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Company Description?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/400</small>
                                                </div>
                                                <Form.Control
                                                    name="company_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Explain here to the AI what your product (or service) is about. Rewrite to get different results."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                            {!['define-company-mission', 'write-vision-statement'].includes(pathName) && (
                                                <Row className="mb-3">
                                                    <Form.Group as={Col}>
                                                        <Form.Label>{t('What is this post about?')}</Form.Label>
                                                        <Form.Control
                                                            name="post_content"
                                                            required
                                                            type="text"
                                                            placeholder={t('Release of our product')}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                    {(['instagram-hashtag-recommendations']).includes(pathName) && (
                                        <Row className="mb-3">
                                            <Form.Group as={Col}>
                                                <Form.Label>{t('Enter a keyword')}</Form.Label>
                                                <Form.Control
                                                    name="post_content"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('e.g yoga')}
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </Row>
                                    )}
                                    {(['personal-social-media-update', 'instagram-post-captions']).includes(pathName) && (
                                        <Row className="mb-3">
                                            <Form.Group as={Col}>
                                                <Form.Label>{t('What is this post about?')}</Form.Label>
                                                <Form.Control
                                                    name="post_content"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder={t('Heading to the beach on weekend')}
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </Row>
                                    )}
                                    {['youtube-video-titles', 'write-video-intros', 'organize-video-scripts', 'generate-video-content'].includes(pathName) && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tone of voice</Form.Label>
                                                <Form.Select name="tone_of_voice">
                                                    <option value="professional">Professional</option>
                                                    <option value="childish">Childish</option>
                                                    <option value="luxurious">Luxurious</option>
                                                    <option value="friendly">Friendly</option>
                                                    <option value="confident">Confident</option>
                                                    <option value="exciting">Exciting</option>
                                                </Form.Select>
                                            </Form.Group>
                                            {(['generate-video-content']).includes(pathName) && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Section title</Form.Label>
                                                    <Form.Control
                                                        name="section_title"
                                                        required
                                                        type="text"
                                                        placeholder="e.g. Benefits of using an AI Copywriter"
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>What is your video about?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="video_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Explain here what your video is about, include as many details as possible. I.e. A video tutorial about boosting your traffic with an AI Writer."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'youtube-video-descriptions') && (
                                        <Form.Group className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Form.Label>What is the title of your video?</Form.Label>
                                                <small className="text-muted">{textAreaValue.length}/100</small>
                                            </div>
                                            <Form.Control
                                                name="video_title"
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="learn how to boost your traffic with an AI-Powered content writing tool."
                                                rows="5"
                                                maxLength={100}
                                                value={textAreaValue}
                                                onChange={(e) => {
                                                    setTextAreaValue(e.currentTarget.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                    {(pathName === 'youtube-tag-generator') && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Enter your video title or a keyword</Form.Label>
                                            <Form.Control
                                                name="video_title"
                                                required
                                                type="text"
                                                placeholder="yoga"
                                            />
                                        </Form.Group>
                                    )}
                                    {(pathName === 'twitter-threads-creation') && (
                                        <Form.Group className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Form.Label>What is this thread about</Form.Label>
                                                <small className="text-muted">{textAreaValue.length}/400</small>
                                            </div>
                                            <Form.Control
                                                name="thread_content"
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="The 5 best Tesla safety features"
                                                rows="5"
                                                maxLength={400}
                                                value={textAreaValue}
                                                onChange={(e) => {
                                                    setTextAreaValue(e.currentTarget.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                    {(pathName === 'twitter-post-ideas') && (
                                        <Form.Group className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Form.Label>What is this tweet about</Form.Label>
                                                <small className="text-muted">{textAreaValue.length}/400</small>
                                            </div>
                                            <Form.Control
                                                name="tweet_content"
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="Tesla Cars"
                                                rows="5"
                                                maxLength={400}
                                                value={textAreaValue}
                                                onChange={(e) => {
                                                    setTextAreaValue(e.currentTarget.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                    {(pathName === 'linkedin-profile-posts') && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Audience</Form.Label>
                                                <Form.Control
                                                    name="audience"
                                                    required
                                                    type="text"
                                                    placeholder="Freelancers, Designers, Teenagers..."
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>What is this post about</Form.Label>
                                                <Form.Control
                                                    name="post_content"
                                                    required
                                                    type="text"
                                                    placeholder="Release of our app"
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {(pathName === 'quora-answer-writing') && (
                                        <Form.Group className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Form.Label>Quora Question</Form.Label>
                                                <small className="text-muted">{textAreaValue.length}/200</small>
                                            </div>
                                            <Form.Control
                                                name="question"
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="What is something that rich people know that the rest of us would benefit from?"
                                                rows="5"
                                                maxLength={200}
                                                value={textAreaValue}
                                                onChange={(e) => {
                                                    setTextAreaValue(e.currentTarget.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}

                                    {(pathName === 'personal-bio-writing') && (
                                        <>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Full Name</Form.Label>
                                                    <Form.Control
                                                        name="full_name"
                                                        required
                                                        type="text"
                                                        placeholder="Elon Musk"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="validationCustom02">
                                                    <Form.Label>Current Position</Form.Label>
                                                    <Form.Control
                                                        name="current_position"
                                                        required
                                                        type="text"
                                                        placeholder="CEO at Tesla"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Current Industry</Form.Label>
                                                    <Form.Control
                                                        name="current_industry"
                                                        required
                                                        type="text"
                                                        placeholder="Cars"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="validationCustom02">
                                                    <Form.Label>Current City</Form.Label>
                                                    <Form.Control
                                                        name="current_city"
                                                        required
                                                        type="text"
                                                        placeholder="Austin, Texas"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Childhood</Form.Label>
                                                <Form.Control
                                                    name="childhood"
                                                    required
                                                    type="text"
                                                    placeholder="Born and raised in United State"
                                                />
                                            </Form.Group>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Hobbies</Form.Label>
                                                    <Form.Control
                                                        name="hobbies"
                                                        required
                                                        type="text"
                                                        placeholder="Bitcoin, Trade"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="validationCustom02">
                                                    <Form.Label>Interests</Form.Label>
                                                    <Form.Control
                                                        name="interests"
                                                        required
                                                        type="text"
                                                        placeholder="Girl"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Skills</Form.Label>
                                                    <Form.Control
                                                        name="skills"
                                                        required
                                                        type="text"
                                                        placeholder="Talking"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="validationCustom02">
                                                    <Form.Label>Character Traits</Form.Label>
                                                    <Form.Control
                                                        name="character_traits"
                                                        required
                                                        type="text"
                                                        placeholder="Hard Worker"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Point of View</Form.Label>
                                                <Form.Select name="point_of_view">
                                                    <option value="first_person">First Person</option>
                                                    <option value="third_person">Third Person</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </>
                                    )}
                                    {['write-newsletters'].includes(pathName) && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tone of voice</Form.Label>
                                                <Form.Select name="tone_of_voice">
                                                    <option value="professional">Professional</option>
                                                    <option value="childish">Childish</option>
                                                    <option value="luxurious">Luxurious</option>
                                                    <option value="friendly">Friendly</option>
                                                    <option value="confident">Confident</option>
                                                    <option value="exciting">Exciting</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Subject</Form.Label>
                                                <Form.Control
                                                    name="subject"
                                                    required
                                                    type="text"
                                                    placeholder="e.g. Benefits of using an AI Copywriter"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control
                                                    name="company_name"
                                                    required
                                                    type="text"
                                                    placeholder="Amazon"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Business Description?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="business_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Briefly describe what your business is about."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    {['meta-description-creation'].includes(pathName) && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Type of Page</Form.Label>
                                                <Form.Select name="type_of_page">
                                                    <option value="landing_page">Landing page</option>
                                                    <option value="product_page">Product page</option>
                                                    <option value="category_page">Category page</option>
                                                    <option value="blog_article">Blog article</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control
                                                    name="website_name"
                                                    required
                                                    type="text"
                                                    placeholder="Shoppe, Google"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Website Description?</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="website_description"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Briefly describe what your website or business is about."
                                                    rows="5"
                                                    maxLength={200}
                                                    value={textAreaValue}
                                                    onChange={(e) => {
                                                        setTextAreaValue(e.currentTarget.value)
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Targeted Keyword</Form.Label>
                                                <Form.Control
                                                    name="targeted_keyword"
                                                    required
                                                    type="text"
                                                    placeholder="car insurance, nyc business lawyer,..."
                                                />
                                            </Form.Group>

                                        </>
                                    )}
                                    {['write-cold-emails'].includes(pathName) && (
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tone of voice</Form.Label>
                                                <Form.Select name="tone_of_voice">
                                                    <option value="professional">Professional</option>
                                                    <option value="childish">Childish</option>
                                                    <option value="luxurious">Luxurious</option>
                                                    <option value="friendly">Friendly</option>
                                                    <option value="confident">Confident</option>
                                                    <option value="exciting">Exciting</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Sender's Name</Form.Label>
                                                    <Form.Control
                                                        name="sender_name"
                                                        required
                                                        type="text"
                                                        placeholder="Adriana"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Recipient's Name</Form.Label>
                                                    <Form.Control
                                                        name="recipient_name"
                                                        required
                                                        type="text"
                                                        placeholder="Elon Musk"
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Sender's Information</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="sender_information"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="e.g. A rising star"
                                                    rows="2"
                                                    maxLength={200}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label>Recipient's Information</Form.Label>
                                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                                </div>
                                                <Form.Control
                                                    name="recipient_information"
                                                    required
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="e.g. Owner of Amazon, an e-commerce retailer."
                                                    rows="2"
                                                    maxLength={200}
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="d-flex bg-light rounded tool-variants align-items-center ps-3 pe-1">
                                                <span className="fw-bold tool-variants-number">{variants}</span>
                                                <div className="d-flex flex-column ms-2">
                                                    <a
                                                        onClick={() => setVariants(variants+1)}
                                                        role="button"
                                                        className="text-secondary"
                                                    >
                                                        <Icon icon="ic:baseline-plus" width="20"/>
                                                    </a>
                                                    <a
                                                        onClick={() => setVariants(variants-1)}
                                                        role="button"
                                                        className="text-secondary"
                                                    >
                                                        <Icon icon="ic:baseline-minus" width="20"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <Button className="ms-2 btn btn-primary btn-lg flex-grow-1" type="submit" disabled={isRunning}>
                                                {isRunning && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                {!isRunning && (<span>Generate</span>)}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                )}
                                {tabIndex === 0 && (
                                <div className="card dock-saved">
                                    <div className="ps-3 pe-3 py-2 bg-white d-flex align-items-center justify-content-between">
                                        <div>
                                            <button
                                                onClick={() => setTabIndexSaved(0)}
                                                className={`btn btn-sm rounded-pill me-2 ${tabIndexSaved === 0 && 'btn-primary'}`}>New</button>
                                            <button
                                                onClick={() => setTabIndexSaved(1)}
                                                className={`btn btn-sm rounded-pill me-2 ${tabIndexSaved === 1 && 'btn-primary'}`}>Saved</button>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        {tabIndexSaved === 0 && (
                                            <>
                                                {!!variantsContent.length && (
                                                    <div className="d-grid gap-2">
                                                        {variantsContent.map((text, index) => (
                                                            <div
                                                                key={index}
                                                                className="card"
                                                            >
                                                                {(processes.includes(index)) && (
                                                                    <div className="position-absolute w-100 h-100 bg-secondary bg-opacity-25 d-flex justify-content-center align-items-center">
                                                                        <div className="spinner-border text-primary" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="mx-3 mt-3" style={{minHeight: 64}}>
                                                                    <div
                                                                        className="text-result-card"
                                                                        dangerouslySetInnerHTML={{__html: text}}
                                                                    />
                                                                    {(!processes.includes(index)) && (
                                                                        <div className="d-flex flex-row-reverse">
                                                                            <CopyButton
                                                                                text={text}
                                                                            />
                                                                            <button
                                                                                onClick={() => !savedIndexes.includes(index) && saveContent(text, index)}
                                                                                className="btn border-0 text-secondary">
                                                                                {savedIndexes.includes(index) && (
                                                                                    <>
                                                                                        <Icon icon="mdi:check-circle" className="text-success" />
                                                                                        <small className="ms-1">Saved</small>
                                                                                    </>
                                                                                )}
                                                                                {!savedIndexes.includes(index) && (
                                                                                    <>
                                                                                        <Icon icon="mdi:content-save-all-outline" />
                                                                                        <small className="ms-1">Save</small>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {!variantsContent.length && (
                                                    <>
                                                        <p className="text-center">{t(td)}</p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {tabIndexSaved === 1 && (
                                            <div className="d-grid gap-2">
                                                {!savedContents.length && (
                                                    <p className="text-center m-5">{t('This is where your saved content will appear.')}</p>
                                                )}
                                                {savedContents.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="card"
                                                    >
                                                        <div className="mx-3 mt-3" style={{minHeight: 64}}>
                                                            <div
                                                                className="text-result-card"
                                                                dangerouslySetInnerHTML={{__html: item.content}}>
                                                            </div>
                                                            <div className="d-flex flex-row-reverse">
                                                                {deletingId === item.id && (
                                                                    <button
                                                                        onClick={() => deleteContent(item.id, index)}
                                                                        className="btn border-0 text-danger">
                                                                        <Icon icon="mdi:delete-outline" />
                                                                        <small className="ms-1">Delete ?</small>
                                                                    </button>
                                                                )}
                                                                {deletingId !== item.id && (
                                                                    <button
                                                                        onClick={() => setDeletingId(item.id)}
                                                                        className="btn border-0 text-secondary">
                                                                        <Icon icon="mdi:delete-outline" />
                                                                        <small className="ms-1">Delete</small>
                                                                    </button>
                                                                )}
                                                                <CopyButton
                                                                    text={item.content}
                                                                />
                                                                <button
                                                                    onClick={() => handleChange([{ method: 'copyText', text: item.content }])}

                                                                    className="btn border-0 text-secondary">
                                                                    <Icon icon="mdi:content-duplicate" />
                                                                    <small className="ms-1">{t('Copy to editor')}</small>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                )}
                                {tabIndex === 1 && (
                                    <div style={{
                                        marginTop: "15px"
                                    }}>
                                        <ChatPage  />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="pt-4 w-100 editor-dock-block">
                            <Routes>
                                <Route exact path="/create" element={<EditorDock props={value}/>}/>
                                <Route exact path="/:hash" element={<EditorDockId/>}/>
                            </Routes>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default connect(({auth, display, common}) => ({auth, display, common}))(UserIndexPageDoc);
