import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Icon} from "@iconify/react/dist/iconify";
import {copyText, hexToRgbA} from "../../../helpers/Utils";
import API from "../../../helpers/Axios";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import SubmittingButton from "../../../components/SubmittingButton";
import {Dropdown} from "react-bootstrap";
import ProjectModals from "../../dashboard/modals/ProjectsModal";
import Form from "react-bootstrap/Form";

const ExportToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn btn-outline-secondary d-flex align-items-center dock-buttons"
    >
        {children}
    </button>
));

const EdittorDock = ({props, common}) => {
    const projectModalRef = useRef(null);
    const generatePDF = () => {
        if(typeof window.jspdf !== "undefined") {
            const elementHTML = `<div style="color: black; font-family: 'Times New Roman',serif">${editor.getData()}<div>`;
            const doc = new window.jspdf.jsPDF;
            doc.setTextColor(0, 0, 0);
            doc.html(elementHTML, {
                callback: function(doc) {
                    doc.save('document.pdf');
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                x: 0,
                y: 0,
                width: 190,
                windowWidth: 675
            });
        } else {
            alert('Loading library, please wait a bit and try later.');
        }
    }

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [editor, setEditor] = useState(null);
    const [getActicle, setActicle] = useState('article-composer');

    const handleEditorLoad = async () => {
        const { default: ClassicEditor } = await import('@ckeditor/ckeditor5-build-classic');
        const newEditor = await ClassicEditor.create(document.querySelector('#editor'));
        setEditor(newEditor);
        newEditor.model.document.on('change:data', () => {
            const data = newEditor.getData();
            setArticle(data);
        });
    }

    useEffect(() => {
        (async () => {
            setTimeout(async () => {
                await handleEditorLoad()
            }, 1000)
        })();
    }, [common.currentProject]);

    const { hash } = useParams();
    const linkTo = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [article, setArticle] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [nameDock, setNameDock] = useState('');

    useEffect(() => {
        if(editor) {
            API.post('auth/document/get-by-hash', {
                hash: hash
            }).then(res => {
                setNameDock(res.data.name);
                editor.setData(res.data.content);
                setArticle(res.data.content);
                setEditorLoaded(true);
            }).catch (error => {
                // linkTo(`/dashboard/documents`);
                setIsLoading(false);
            });
        }
    }, [editor]);
    useEffect(() => {
        if(props) {
            if(props[0].method === "type") {
                setActicle(props[0].text);
            }
            if(props[0].method === "copyText") {
                console.log('Начало');
                if(editor) {
                    editor.setData(editor.getData() + '<p>-----</p>' +
                        props[0].text);
                }
            }
        }
    }, [props]);

    const [isSubmitting, setIsSubmitting] = useState( false);

    useEffect(() => {
        if(isCopied) {
            setTimeout(() => setIsCopied(false), 5000)
        }
    }, [isCopied])

    return isLoading ? (<div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >Loading...</div>) : (
        <>
            <ProjectModals
                ref={projectModalRef}
            />
            <div className="w-100">
                <div className="d-flex align-items-center justify-content-between bg-white p-3 rounded">
                    <div className="d-flex align-items-center">
                        <a
                            onClick={(e) => {
                                linkTo(`/dashboard/documents`);
                                e.preventDefault();
                            }}
                            href={`/dashboard/documents`}
                            className="btn btn-outline-secondary">
                            <Icon icon="ic:outline-arrow-back" />
                        </a>
                        <Form.Control
                            name="title"
                            className="dock-name"
                            required
                            type="text"
                            placeholder={t('Enter name for document')}
                            value={nameDock}
                            onChange={e => {
                                setNameDock(e.target.value);
                            }}
                        />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center gap-4">
                            {[
                                {
                                    title: 'words',
                                    content: article.trim().split(/\s+/).length
                                },
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    className="d-flex flex-column"
                                >
                                    <span className="fw-bold">{item.content}</span>
                                    <small className="text-secondary">{item.title}</small>
                                </div>
                            ))}
                        </div>
                        <div className="vr ms-3 me-3"></div>
                        <div className="card folders_left mb-0">
                            <button
                                onClick={() => projectModalRef.current?.setShowProject(true)}
                                className="p-0 px-3 my-2 border-0 bg-transparent d-flex align-items-center justify-content-between dock-buttons-progect">
                                <div className="d-flex flex-column align-items-start">
                                    <small className="text-muted text-uppercase smaller">{t("Project")}</small>
                                    <small className="fw-bold">{common.currentProject ? common.currentProject.name : t('Personal Folder')}</small>
                                </div>

                            </button>
                        </div>
                        <button
                            onClick={() => {
                                copyText(article.replace(/<\/?[^>]+>/g, '').replace(/<\/?p>/g, '\n'));
                                setIsCopied(true);
                            }}
                            className="btn btn-outline-secondary d-lg-flex d-none align-items-center dock-buttons">
                            {isCopied && (
                                <>
                                    <Icon icon="mdi:check-circle" className="text-success" />

                                </>
                            )}
                            {!isCopied && (
                                <>
                                    <Icon icon="mdi:content-copy" />

                                </>
                            )}
                        </button>
                        <Dropdown>
                            <Dropdown.Toggle as={ExportToggle}>
                                <Icon icon="mdi:export"/>

                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={generatePDF}
                                >
                                    <small className="flex-grow-1 ms-2 text-wrap">{t('PDF')}</small>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        copyText(article.replace(/<\/?[^>]+>/g, '').replace(/<\/?p>/g, '\n'));
                                        setIsCopied(true);
                                    }}
                                >
                                    <small className="flex-grow-1 ms-2 text-wrap">{t('Copy')}</small>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <SubmittingButton
                            onClick={() => {
                                setIsSubmitting(true);
                                API.post('auth/document/save', {
                                    hash: hash,
                                    name: nameDock,
                                    type: getActicle,
                                    content: article,
                                    tokens: article.trim().split(/\s+/).length
                                }).then(res => {
                                    setIsSubmitting(false);
                                }).catch (error => {
                                    setIsSubmitting(false);
                                });
                            }}
                            isSubmitting={isSubmitting}
                        />
                    </div>
                </div>
                <div className="card p-3 mt-3">
                    <div>
                        {!editorLoaded && <div>Loading editor...</div>}
                        <div
                            id="editor"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(({auth, common}) => ({auth, common}))(React.memo(EdittorDock));

