import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Icon} from "@iconify/react";
import {hexToRgbA} from "../helpers/Utils";
import {useTranslation} from "react-i18next";
import API from "../helpers/Axios";
import {store} from "../store/configureStore";
import i18n from "i18next";

const ToolCard = ({item}) => {
    const linkTo = useNavigate();
    const { t } = useTranslation();
    const { slug } = useParams();


    function setFav(alt_name) {
        var element = document.getElementById(alt_name);
        element.style.color = "#ffc300";
    }

    function unSetFav(alt_name) {
        var element = document.getElementById(alt_name);
        element.style.color = "#4C4E64";
    }





    return (
        <div className="col-lg-4 col-12">
                <div className="h-100 card p-3 custom-card">
                    <div className="d-flex align-items-center justify-content-between mb-3 custom-tools-card">
                        <div className="card-tool-icon" style={{backgroundColor:("#e7e7e7")}}>
                            <Icon icon={item.icon_name} style={{color: "black"}} width="38px" height="30px" />
                        </div>
                        <div className="custom-tools">
                             <p className="fw-bold mb-1"><a className="text-decoration-none link-dark"
                                                            onClick={(e) => {
                                                                linkTo(`/dashboard/tools/${item.alt_name}`);
                                                                e.preventDefault();
                                                            }}
                                                            href={`/dashboard/tools/${item.alt_name}`}
                             >{t(item.title)}</a></p>
                            <p className="mb-0 custom-tools-bottom-text">{t(item.type)}</p>
                        </div>
                        <div onClick={(e) => {
                            API.post('auth/favorites/editfav', {
                                type: item.alt_name
                            }).then(res => {
                                if(res.data.status === 'set') {
                                    setFav(item.alt_name);
                                }

                                if(res.data.status === 'unset') {
                                    unSetFav(item.alt_name);
                                }
                            }).catch(error => {

                            });
                        }
                        }>
                            <Icon style={{
                            cursor:"pointer"
                            }
                            } icon="ic:baseline-star-border" id={item.alt_name} width="40px" height="23px"/>
                        </div>
                    </div>
                    <p className="mb-0 text-secondary">{t(item.description)}</p>
                    <div>
                        <hr />
                    </div>
                    <div className="bottom-tool-card">
                        <div className="bottom-tool-card-tag">
                            <span>POPULAR</span>
                        </div>
                        <div className="bottom-tool-card-comments">
                            <Icon icon="ic:outline-mode-comment" width="40px" height="23px"/>
                            <span>0</span>
                        </div>
                    </div>
                </div>
        </div>
    )
}
export default ToolCard;
