import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import AllToolsPage from "./AllToolsPage";
import CommonToolPage from "./CommonToolPage";
import DashboardPage from "./DashboardPage";
import ArticleGeneratorPage from "./ArticleGeneratorPage";
import BlogWriterPage from "./BlogWriterPage";
import BlogWriterContentPage from "./BlogWriterContentPage";
import {Icon} from "@iconify/react";
import ContentReframerPage from "./ContentReframerPage";
import {Dropdown} from "react-bootstrap";
import {store} from "../../store/configureStore";
import ProjectModals from "./modals/ProjectsModal";
import BillingPage from "./BillingPage";
import SettingsPage from "./SettingsPage";
import PlansPage from "./PlansPage";
import MyContentPage from "./MyContentPage";
import {hexToRgbA} from "../../helpers/Utils";
import {useTranslation} from "react-i18next";
import MyContentEditPage from "./MyContentEditPage";
import DocumentsPage from  "./DocumentsPage";
import tools from "../../helpers/tools.json";
import UserAvatar from "../../components/UserAvatar";
import SubscribeBar from "../../components/SubscribeBar";
import ImageGeneratorPage from "./ImageGeneratorPage";
import i18n from "i18next";
import ChatPage from "./ChatPage";

const AccountToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn border-0 p-0 d-flex align-items-center bg-link"
    >
        {children}
    </button>
));

const CreateContentToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn d-flex align-items-center custom-btn-drowdown"
    >
        {children}
    </button>
));

const UserIndexPage = ({auth, display, common}) => {
    const { t } = useTranslation();
    const linkTo = useNavigate();
    const projectModalRef = useRef(null);
    const [isSideBarShown, setIsSideBarShown] = useState(false);
    const [engineTools, setEngineTools] = useState(tools);

    function searchArrayByToolTitle(array, searchTerm) {
        return array.map(item => ({
            ...item,
            tools: item.tools.filter(tool => tool.title.toLowerCase().includes(searchTerm.toLowerCase()))
        })).filter(item => item.tools.length > 0);
    }

    const sidebarMenu = (title, iconString, link) => {
        return (
            <li className="nav-item">
                <a
                    onClick={(e) => {
                        linkTo(`${link}`);
                        e.preventDefault();
                    }}
                    href={`${link}`}
                    className={`d-flex align-items-center ${pathName === link && 'active'}`} aria-current="page">
                    <Icon icon={iconString} width="24"/>
                    <span className="ms-2 custom-text-menu">{title}</span>
                </a>
            </li>
        )
    }

    const switchLanguage = (lang) => {
        fetch(`/assets/lang/${lang}.json`)
            .then((response) => response.json())
            .then((data) => {
                i18n.addResourceBundle(lang, 'translation', data);
            }).then(() => {
            i18n.changeLanguage(lang);
            store.dispatch({type: 'TOGGLE_DARK_MODE'});
            store.dispatch({type: 'SWITCH_LANGUAGE', lang: lang});
        });
    }

    const [pathName, setPathName] = useState('');

    useEffect(() => {
        setIsSideBarShown(false);
        setPathName(window.location.pathname);
    }, [window.location.pathname])

    return (
        <>
            <ProjectModals
                ref={projectModalRef}
            />
            <div className="w-100 min-vh-100 bg-light">
                <header className="dashboard-header w-100 position-sticky border-bottom bg-light">
                    <SubscribeBar/>
                    <div className="w-100 px-lg-5 px-3 py-3">
                        <div className="d-flex flex-md-row align-items-center justify-content-between">
                            <button
                                onClick={() => {
                                    setIsSideBarShown(true)
                                }}
                                className="d-lg-none d-block border-0 bg-transparent">
                                <Icon icon="charm:menu-hamburger" width="32" className="text-secondary"/>
                            </button>
                            <div className="d-lg-flex d-none align-items-center">
                                <a
                                    onClick={(e) => {
                                        linkTo(`/dashboard`);
                                        e.preventDefault();
                                    }}
                                    href={`/dashboard`}
                                    className="d-flex align-items-center text-decoration-none text-dark"
                                >
                                    <img width="250" height="40" src="/assets/images/Logo232.svg"/>
                                </a>
                                <nav className="d-lg-inline-flex d-none ms-5 gap-4">
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/dashboard`);
                                            e.preventDefault();
                                        }}
                                        href={`/dashboard`}
                                        className="me-3 py-2 text-dark text-decoration-none">{t('Dashboard')}</a>
                                </nav>
                            </div>
                            <nav className="d-flex align-content-center gap-3">

                                <Dropdown
                                    className="ms-2"
                                >
                                    <Dropdown.Toggle
                                        className="btn-outline-primary bg-transparent border-0"
                                    >
                                        <img width="24" height="24" className="rounded" src={`/assets/flags/${display.language}.svg`}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {[
                                            {
                                                name: 'English',
                                                code: 'en',
                                            },
                                            {
                                                name: 'Русский',
                                                code: 'ru',
                                            }
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    switchLanguage(item.code)
                                                }}
                                                key={index}
                                                className="d-flex align-items-center py-2"
                                            >
                                                <img width="24" height="24" className="rounded" src={`/assets/flags/${item.code}.svg`} alt={item.name}/>
                                                <small className="ms-2">{item.name}</small>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown align="end"
                                          onClick={(e) => {
                                              linkTo(`/dashboard/tools`);
                                              e.preventDefault();
                                          }}
                                          href={`/dashboard/tools`}>
                                    <Dropdown.Toggle as={CreateContentToggle}>
                                        <Icon icon="mdi:plus-box-outline" width="24"/>
                                    </Dropdown.Toggle>

                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle as={AccountToggle}>
                                        <div className="rounded-circle overflow-hidden">
                                            <UserAvatar
                                                name={auth.user.name}
                                                url={auth.user.artwork_url}
                                                width="38"
                                            />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="d-flex align-items-center px-3 py-2">
                                            <div className="me-2 rounded-circle overflow-hidden">
                                                <UserAvatar
                                                    name={auth.user.name}
                                                    url={auth.user.artwork_url}
                                                    width="38"
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-dark fw-bold">{auth.user.name}</small>
                                                <div className="d-flex align-items-center">
                                                    <Icon icon="material-symbols:token-outline" width="20" className="text-warning"/>
                                                    <span className="text-secondary fw-bold ms-1">{common.tokens}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-1"/>
                                        {[
                                            {
                                                iconName: 'eos-icons:package-upgrade-outlined',
                                                title: t('Upgrade Account'),
                                                link: '/dashboard/plans'
                                            },
                                            {
                                                iconName: 'material-symbols:credit-card-outline',
                                                title: t('Billing & Plan'),
                                                link: '/dashboard/billing'
                                            },
                                            {
                                                iconName: 'carbon:settings',
                                                title:  t('Settings'),
                                                link: '/dashboard/settings'
                                            },
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                onClick={(e) => {
                                                    linkTo(item.link);
                                                    e.preventDefault();
                                                }}
                                                href={item.link}
                                            >
                                                <div className="d-flex align-items-center my-1">
                                                    <Icon icon={item.iconName} width="24"/>
                                                    <span className="ms-3">{item.title}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        <hr className="my-1"/>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                linkTo(`/`);
                                                store.dispatch({type: 'UPDATE_ACCESS_TOKEN', accessToken: ''});
                                                store.dispatch({type: 'TOGGLE_AUTH', user: {}});
                                                e.preventDefault();
                                            }}
                                            href="/sign-out"
                                        >
                                            <div className="d-flex align-items-center my-1">
                                                <Icon icon="uil:signout" width="24"/>
                                                <span className="ms-3">{t('Sign out')}</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </nav>
                        </div>
                    </div>
                </header>
                <main className="dashboard-main px-lg-5 px-3 pb-5">
                    <div className="d-flex flex-nowrap">
                        <div className={`dashboard-main-sidebar pt-lg-4 ${isSideBarShown && 'm-shown'}`}>
                            <div className="h-100 d-flex flex-column justify-content-between">
                                <div className="d-lg-none d-flex align-items-center justify-content-between mb-4">
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/dashboard`);
                                            e.preventDefault();
                                        }}
                                        href={`/dashboard`}
                                        className="d-flex align-items-center text-decoration-none text-dark">
                                        <img width="120" height="40" src="/assets/images/Logo232.svg"/>
                                    </a>
                                    <button
                                        className="border-0 bg-transparent"
                                        onClick={() => setIsSideBarShown(false)}
                                    >
                                        <Icon icon="ion:close" className="text-secondary" width="28"/>
                                    </button>
                                </div>
                                <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 flex-grow-1">
                                    <div className="d-flex align-items-center justify-content-between w-100 mb-2 custom-text-space">
                                        <a
                                            onClick={(e) => {
                                                linkTo(`/dashboard/billing`);
                                                e.preventDefault();
                                            }}
                                            href={`/dashboard/billing`}
                                            className="fw-bold text-decoration-none text-secondary"
                                        >{auth.user.subscription ? auth.user.subscription.service.title : t('Free trial')}</a>
                                        <small><span className="fw-bold">{common.tokens}</span> {t('words left')}</small>
                                    </div>
                                    <div className="progress w-100 progress-sm">
                                        <div className={`progress-bar ${(common.tokens/1500)*100 > 20 ? 'bg-success' : 'bg-danger'}`} role="progressbar" style={{width: `${(common.tokens/1500)*100}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    {!auth.user.subscription && (
                                        <a
                                            onClick={(e) => {
                                                linkTo(`/dashboard/plans`);
                                                e.preventDefault();
                                            }}
                                            href={`/dashboard/plans`}
                                            className="w-100 btn btn-outline-primary mt-3 сustom-update-button"
                                        >
                                            <span>• {t('Upgrade')}</span>
                                        </a>
                                    )}
                                    <div className="card folders_left mb-0">
                                        <button
                                            onClick={() => projectModalRef.current?.setShowProject(true)}
                                            className="p-0 px-3 my-2 border-0 bg-transparent d-flex align-items-center justify-content-between">
                                            <div className="d-flex flex-column align-items-start">
                                                <small className="text-muted text-uppercase smaller">{t("Project")}</small>
                                                <small className="fw-bold">{common.currentProject ? common.currentProject.name : t('Personal Folder')}</small>
                                            </div>
                                            <Icon icon="uil:sort" width="24" className="text-muted"/>
                                        </button>
                                    </div>

                                    <ul className="nav nav-pills nav-sidebar flex-column mb-auto w-100">
                                        {sidebarMenu(t('Dashboard'), 'mingcute:dashboard-3-line', '/dashboard')}
                                        {sidebarMenu(t('All tools'), 'tabler:tools', '/dashboard/tools')}
                                        {sidebarMenu(t('My content'), 'material-symbols:folder-copy-outline', '/dashboard/my-content')}
                                        {sidebarMenu(t('My documents'), 'mdi:file-document-multiple-outline', '/dashboard/documents')}
                                        {sidebarMenu(t('Chat'), 'material-symbols:mark-unread-chat-alt-outline', '/dashboard/chat')}
                                        {sidebarMenu(t('Image Generator'), 'ph:image-square-fill', '/dashboard/tools/image-generator')}
                                        <hr />
                                        {sidebarMenu(t('Article Composer'), 'majesticons:article-line', '/dashboard/tools/article-composer')}
                                        {sidebarMenu(t('Content Rewrite'), 'majesticons:repeat-circle-line', '/dashboard/tools/content-rewrite')}
                                    </ul>
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-main-routes pt-4 w-100">
                            <Routes>
                                <Route exact path="/" element={<DashboardPage/>}/>
                                <Route exact path="/billing" element={<BillingPage/>}/>
                                <Route exact path="/settings" element={<SettingsPage/>}/>
                                <Route exact path="/plans" element={<PlansPage/>}/>
                                <Route exact path="/my-content" element={<MyContentPage/>}/>
                                <Route exact path="/my-content/:slug/:id" element={<MyContentEditPage/>}/>
                                <Route exact path="/documents" element={<DocumentsPage/>}/>
                                <Route exact path="/chat" element={<ChatPage/>}/>
                                <Route exact path="/tools" element={<AllToolsPage/>}/>
                                <Route exact path="/tools/article-composer/:id?" element={<ArticleGeneratorPage/>}/>
                                <Route exact path="/tools/blog-writing-assistant" element={<BlogWriterPage/>}/>
                                <Route exact path="/tools/blog-writing-assistant/content" element={<BlogWriterContentPage/>}/>
                                <Route exact path="/tools/content-rewrite" element={<ContentReframerPage/>}/>
                                <Route exact path="/tools/image-generator" element={<ImageGeneratorPage/>}/>
                                <Route exact path="/tools/:slug" element={<CommonToolPage/>}/>
                            </Routes>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default connect(({auth, display, common}) => ({auth, display, common}))(UserIndexPage);
