import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ToolCard from "../../components/ToolCard";

import {useTranslation} from "react-i18next";
import API from "../../helpers/Axios";
import tools from "../../helpers/tools.json";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";

const AllToolsPage = () => {
    const { t } = useTranslation();
    const linkTo = useNavigate();
    const [tabIndex, setTabIndex] = useState(
        0
    );

    const [tools, settools] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [contentWithTools, setContentWithTools] = useState([]);

    const [fav, setFavs] = useState([]);


    useEffect(() => {

        API.get('auth/favorites/getools', {

        }).then(res => {
            settools(res.data);
        })

        API.post('auth/favorites/getfav', {

        }).then(res => {
            setFavs(res.data);
        }).catch(error => {

        });

    }, []);

    function setFav(alt_name) {
        var element = document.getElementById(alt_name);
        element.style.color = "#ffc300";
    }

    function unSetFav(alt_name) {
        var element = document.getElementById(alt_name);
        element.style.color = "#4C4E64";
    }


    useEffect(() => {
        API.post('auth/favorites/getools', {
            index: tabIndex
        }).then(res => {

            API.post('auth/favorites/getfav', {

            }).then(res => {
                res.data.forEach(out => {
                    setFav(out.type);
                    console.log(out.type);
                })
            }).catch(error => {

            });




            const resultArray = res.data.tools.map(item => {
                const icon_name = item.icon_name;
                const icon_color = item.icon_color;
                const alt_name = item.alt_name;
                const title = item.title;
                const description = item.description;
                const type = item.type;
                {fav.map((fav_info) => (
                    (fav_info.type === item.alt_name ? item.favorite = "#ffba00":'')
                ))}
                const favorit = item.favorite;
                return { icon_name, icon_color, alt_name,  title, description, type, favorit };
            });

            const grouped = resultArray.reduce((acc, cur) => {
                const groupKey = cur.groupTitle;
                const group = acc.find(group => group.groupTitle === groupKey);
                if (group) {
                    group.items.push(cur);
                } else {
                    acc.push({ groupTitle: groupKey, items: [cur] });
                }
                return acc;
            }, []);

            setContentWithTools(grouped);
            setIsLoading(false);
        })
    }, [tabIndex]);


    const renderItem = ((item, index) => (
        <div className="col-lg-4 col-12">
            <div className="h-100 card p-3 custom-card">
                <div className="d-flex align-items-center justify-content-between mb-3 custom-tools-card">
                    <div className="card-tool-icon" style={{backgroundColor:("#e7e7e7")}}>
                        <Icon icon={item.icon_name} style={{color: "black"}} width="38px" height="30px" />
                    </div>
                    <div className="custom-tools">
                        <p className="fw-bold mb-1"><a className="text-decoration-none link-dark"
                                                       onClick={(e) => {
                                                           linkTo(`/dashboard/tools/${item.alt_name}`);
                                                           e.preventDefault();
                                                       }}
                                                       href={`/dashboard/tools/${item.alt_name}`}
                        >{t(item.title)}</a></p>
                        <p className="mb-0 custom-tools-bottom-text">{t(item.type)}</p>
                    </div>
                    <div onClick={(e) => {
                        API.post('auth/favorites/editfav', {
                            type: item.alt_name
                        }).then(res => {
                            if(res.data.status === 'set') {
                                setFav(item.alt_name);
                            }

                            if(res.data.status === 'unset') {
                                unSetFav(item.alt_name);
                            }
                        }).catch(error => {

                        });
                    }
                    }>
                        <Icon style={{
                            cursor:"pointer",
                            color:item.favorit

                        }
                        } icon="ic:baseline-star-border" id={item.alt_name} width="40px" height="23px"/>
                    </div>
                </div>
                <p className="mb-0 text-secondary">{t(item.description)}</p>
                <div>
                    <hr />
                </div>
                <div className="bottom-tool-card">
                    <div className="bottom-tool-card-tag">
                        <span>POPULAR</span>
                    </div>
                    <div className="bottom-tool-card-comments">
                        <Icon icon="ic:outline-mode-comment" width="40px" height="23px"/>
                        <span>0</span>
                    </div>
                </div>
            </div>
        </div>
    ));






    return (
        <>
            <div className="d-flex align-items-center">
                <h1>{t('All tools')}</h1>
            </div>
            <ul className="nav nav-tabs nav-tabs-none-bg mt-3">
                {tools.map((tool, index) => (

                    <li

                    className="nav-item"
                    >
                    <button
                    onClick={() => {
                        setContentWithTools(false);
                        setTabIndex(index)
                    }}
                    className={`nav-link text-dark d-flex align-items-center ${tabIndex === index && 'active'}`}
                    aria-current="page"
                    >
                    <span>{tool.title} </span>
                    <span className="ms-2 badge rounded-pill fw-light">{tool.tools.length}</span></button>
                    </li>
                ))}
            </ul>
            <div className="row mt-4 tools-list g-lg-4 g-3">
                {(!contentWithTools || !contentWithTools.length) ? (
                    <div className="p-5 text-center">
                       Loading...
                    </div>
                ): (
                    <>
                        {contentWithTools.map((group, index) => group.items.map((item, index) => renderItem(item, index)))}
                    </>
                )}
            </div>
        </>
    )
}

export default connect(({auth}) => ({auth}))(React.memo(AllToolsPage));

