import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import {Icon} from "@iconify/react/dist/iconify";
import {hexToRgbA} from "../../helpers/Utils";
import API from "../../helpers/Axios";
import {Dropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import tools from "../../helpers/tools.json";
import {type} from "@testing-library/user-event/dist/type";
import {t} from "i18next";

const DocumentsPage = ({common}) => {

    const [contentWithTools, setContentWithTools] = useState([]);
    const linkTo = useNavigate();
    const [tabIndex, setTabIndex] = useState(
        JSON.parse(localStorage.getItem("tabIndex")) || 0
    );
    const [isLoading, setIsLoading] = useState(true);

    const getContents = () => {
        API.post('auth/documents').then(res => {

            const filteredResponse = res.data.data.filter(item => {
                return tools.some(toolItem => toolItem.tools.some(tool => tool.alt_name === item.type));
            });

            const resultArray = filteredResponse.map(item => {
                const type = item.type;
                const matchingToolItem = tools.find(toolItem => toolItem.tools.some(tool => tool.alt_name === item.type));
                const groupTitle = matchingToolItem.title;
                const tool = matchingToolItem.tools.find(tool => tool.alt_name === item.type);
                const toolIcon = tool.icon_name;
                const toolTitle = tool.title;
                const toolColor = tool.icon_color;
                const content = item.content;
                const id = item.id;
                const hash = item.hash;
                const name = item.name;
                const created_at = item.created_at.split('T')[0];
                const updated_at = item.updated_at;
                const project_folder = item.project_folder ? item.project_folder : t('Personal Folder');
                const tokens = item.tokens;

                return { id, name, groupTitle, toolIcon, toolTitle, toolColor, type, content, created_at, updated_at, hash, project_folder, tokens };
            });

            const grouped = resultArray.reduce((acc, cur) => {
                const groupKey = cur.groupTitle;
                const group = acc.find(group => group.groupTitle === groupKey);
                if (group) {
                    group.items.push(cur);
                } else {
                    acc.push({ groupTitle: groupKey, items: [cur] });
                }
                return acc;
            }, []);

            setContentWithTools(grouped);
            setIsLoading(false);
        }).catch (error => {

        });
    }

    useEffect(() => {
        setIsLoading(true);
        setTabIndex(0);
        getContents();
    }, [common.currentProject]);

    const deleteContentById = (hash) => {
        if (window.confirm(t('Are you sure you want to delete this content?'))) {
            API.post('auth/document/delete', {
                hash: hash
            }).then(res => {
                getContents()
            }).catch(error => {

            });
        }
    }

    const contentToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn btn-outline-secondary d-flex align-items-center justify-content-center p-0 w-40px"
        >
            {children}
        </button>
    ));

    const buildLink = (item) => {
        console.log(item);
        return (`/documents/${item.hash}`);
    }

    const renderItem = ((item, index) => (

                <div className="col-lg-4 col-12">
                    <div className="h-100 card p-3 custom-card">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="custom-documents">
                                <div style={{backgroundColor: hexToRgbA(item.toolColor),
                                    marginRight: "1em"}} className="w-40px d-flex justify-content-center align-items-center rounded">
                                    <Icon icon={item.toolIcon} style={{color: item.toolColor}} width="24" />
                                </div>
                                <div>
                                    <a className="custom-documents_a" onClick={(e) => {
                                        linkTo(buildLink(item));
                                        e.preventDefault();
                                    }}
                                       href={buildLink(item)}>
                                        <span>{item.name}</span>
                                    </a>
                                    <div>
                                        <span>{t('Progect')}: {item.project_folder}</span>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <a className="custom-documents_a" onClick={(e) => {
                                    linkTo(buildLink(item));
                                    e.preventDefault();
                                }}
                                   href={buildLink(item)}>
                                    <Icon icon="material-symbols:edit-outline" width="30"/>
                                </a>
                            </div>
                        </div>

                        <div>
                            <hr />
                        </div>
                        <div className="bottom-tool-card">
                            <div>
                                <span><strong>Created: </strong>{item.created_at}</span>
                            </div>
                            <div className="bottom-tool-card-tag">
                                <span>{item.tokens} {t('Words')}</span>
                            </div>
                        </div>
                    </div>
                </div>

    ));


    return isLoading ? (<div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >{t('Loading...')}</div>) : (
        <>
            <div className="header-dock-title">
                <div>
                    <h1>{t('My documents')}</h1>
                    <p className="text-muted">{t('Find all your articles and any saved content below.')}</p>
                </div>
                <div>
                    <a
                        onClick={(e) => {
                            linkTo(`/documents/create`);
                            e.preventDefault();
                        }}
                        href={`/documents/create`}
                        className="btn btn_to_bot">{t('Create document')}</a>
                </div>
            </div>

            {(!contentWithTools || !contentWithTools.length) ? (
                    <div className="p-5 text-center">
                        <p>{t('You haven\'t created any automated content yet. Head to the Tools page to start the miracle.')}</p>
                        <a
                            onClick={(e) => {
                                linkTo(`/documents/create`);
                                e.preventDefault();
                            }}
                            href={`/documents/create`}
                            className="btn btn-outline-primary">{t('Create document')}</a>
                    </div>
                ): (
            <div className="row mt-3 mx-lg-0 mx-1">
                {tabIndex === 0 && contentWithTools.map((group, index) => group.items.map((item, index) => renderItem(item, index)))}
                {tabIndex !== 0 && typeof contentWithTools[tabIndex-1] !== undefined && contentWithTools[tabIndex-1].items.map((item, index) => renderItem(item, index))}
                </div>
                )}

        </>
    )
}

export default connect(({auth, common}) => ({auth, common}))(React.memo(DocumentsPage));

