import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import HomePage from "./HomePage";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import i18n from "i18next";
import {store} from "../store/configureStore";
import { Icon } from '@iconify/react';
import API from "../helpers/Axios";
import StaticPage from "./StaticPage";
import BlogPage from "./BlogPage";

const IndexPage = ({auth, display}) => {
    const linkTo = useNavigate();
    const { t } = useTranslation();
    const switchLanguage = (lang) => {
        fetch(`/assets/lang/${lang}.json`)
            .then((response) => response.json())
            .then((data) => {
                i18n.addResourceBundle(lang, 'translation', data);
            }).then(() => {
            i18n.changeLanguage(lang);
            store.dispatch({type: 'SWITCH_LANGUAGE', lang: lang});
        });
    }

    const [pages, setPages] = useState([]);
    useEffect(() => {
        API.post('/pages')
            .then(res => {
                setPages(res.data)
            }).catch (error => {
            console.log(error);
        });
    }, []);

    return (
        <div className="w-100">
            <div className="hero-bg"></div>
            <div className="container py-3">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between pb-3 mb-4 border-bottom">
                        <div className="d-flex align-items-center text-dark text-decoration-none">
                            <div className="d-flex align-items-center">
                                <a
                                    onClick={(e) => {
                                        linkTo(`/`);
                                        e.preventDefault();
                                    }}
                                    href={`/`}
                                    className="d-flex align-items-center text-decoration-none text-dark">
                                    <img width="120" height="40" src="/assets/images/Logo232.svg"/>
                                </a>
                            </div>
                        </div>
                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="/#hiw">{t('How it works')}</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="/#us">{t('Use Cases')}</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="/#features">{t('Futures')}</a>
                            <a className="py-2 text-dark text-decoration-none" href="/#pricing">{t('Pricing')}</a>
                        </nav>
                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            {auth.isLogged && (
                                <>
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/dashboard`);
                                            e.preventDefault();
                                        }}
                                        href={`/dashboard`}
                                        className="btn btn-outline-primary"
                                    >{t('my_dashboard')}</a>
                                </>
                            )}
                            {!auth.isLogged && (
                                <>
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/sign-in`);
                                            e.preventDefault();
                                        }}
                                        href={`/sign-in`}
                                        className="me-3 py-2 text-dark text-decoration-none">{t('Login')}</a>
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/sign-up`);
                                            e.preventDefault();
                                        }}
                                        href={`/sign-up`}
                                        className="btn btn-outline-primary"
                                    >{t('Start Free Trial')}</a>
                                </>
                            )}
                            <Dropdown
                                className="ms-2"
                            >
                                <Dropdown.Toggle
                                    className="btn-outline-primary bg-transparent border-0"
                                >
                                    <img width="24" height="24" className="rounded" src={`/assets/flags/${display.language}.svg`}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    {[
                                        {
                                            name: 'English',
                                            code: 'en',
                                        },
                                        {
                                            name: 'Русский',
                                            code: 'ru',
                                        }
                                    ].map((item, index) => (
                                        <Dropdown.Item
                                            onClick={() => {
                                                switchLanguage(item.code)
                                            }}
                                            key={index}
                                            className="d-flex align-items-center py-2"
                                        >
                                            <img width="24" height="24" className="rounded" src={`/assets/flags/${item.code}.svg`} alt={item.name}/>
                                            <small className="ms-2">{item.name}</small>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </nav>
                    </div>
                </header>
                <main>
                    <div className="row mt-5 py-lg-5 ">
                        <div className="col-lg-6 col-12 py-lg-5 pe-lg-5 px-3"><small
                            className="text-uppercase text-primary fw-bold">{t('Write better, faster')}</small>
                            <h1 className="mb-3 mt-3 fw-bolder">{t('Generate Content AI')}</h1>
                            <p className="fs-5 text-secondary fw-light">{t('b1')}</p>
                            <div className="d-flex align-items-center mt-5"><a href="/sign-up"
                                                                               className="btn btn-lg btn-primary">{t('Start Writing For Free')}</a><span className="ms-4 text-secondary fw-light d-lg-block d-none">{t('No credit card required')}</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mt-lg-0 mt-5 px-3">
                            <div className="d-flex justify-content-center align-items-center overflow-hidden"><img
                                src="/assets/images/landing-1.png" alt="" className="w-100 rounded-5"></img></div>
                        </div>
                    </div>
                    <div id="hiw" className="my-5 py-lg-5">
                        <div className="mt-5 mb-5 text-center"><small
                            className="text-uppercase text-primary mb-0 fw-bold">{t('Start writing in 3 easy steps')}</small>
                            <h1 className="fw-bolder">{t('How does it work?')}</h1>
                        </div>
                        <div className="row hiw-cards">
                            <div className="col-md-6 col-lg-4 column">
                                <div className="card gr-1">
                                    <div className="txt">
                                        <h1>{t('Select a writing tool')}</h1>
                                        <p className="fw-light">{t('Select from many AI writing tools.')}</p>
                                    </div>
                                    <div className="ico-card">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--ic" width="40" height="40" viewBox="0 0 24 24">
                                            <g fill="none" stroke="currentColor" stroke-linecap="round"
                                               stroke-linejoin="round" stroke-width="2">
                                                <path
                                                    d="m16 12l4-4a2.828 2.828 0 1 0-4-4l-4 4m-2 2l-7 7v4h4l7-7m.5-8.5l4 4"></path>
                                                <path
                                                    d="M12 8L7 3M5 5L3 7l5 5M7 8L5.5 9.5M16 12l5 5m-2 2l-2 2l-5-5m4 1l-1.5 1.5M3 3l18 18"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <span>{t('Step 1')}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 column">
                                <div className="card gr-2">
                                    <div className="txt">
                                        <h1>{t('Fill in your product details')}</h1>
                                        <p className="fw-light">{t('Fully detail topic for AI writing.')}</p>
                                    </div>
                                    <div className="ico-card">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--ic" width="40" height="40" viewBox="0 0 24 24">
                                            <path fill="currentColor"
                                                  d="M11 17h2v-6h-2v6Zm1-8q.425 0 .713-.288T13 8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8q0 .425.288.713T12 9Zm0 13q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"></path>
                                        </svg>
                                    </div>
                                    <span>{t('Step 2')}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 column">
                                <div className="card gr-3">
                                    <div className="txt">
                                        <h1>{t('Generate AI content')}</h1>
                                        <p className="fw-light">{t('Get unique, human-like content')}</p>
                                    </div>
                                    <div className="ico-card">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--ic" width="40" height="40" viewBox="0 0 24 24">
                                            <path fill="currentColor"
                                                  d="M21 12.4V7l-4-4H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7.4l2-2H5V5h11.17L19 7.83v6.57l2-2zM15 15c0 1.66-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3zM6 6h9v4H6V6zm13.99 10.25l1.77 1.77L16.77 23H15v-1.77l4.99-4.98zm3.26.26l-.85.85l-1.77-1.77l.85-.85c.2-.2.51-.2.71 0l1.06 1.06c.2.2.2.52 0 .71z"></path>
                                        </svg>
                                    </div>
                                    <span>{t('Step 3')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="us" className="row mt-5 mb-5 py-5">
                        <div className="col-lg-5 col-12">
                            <div className="h-100 d-flex align-items-center px-lg-5 px-2">
                                <div>
                                    <h1 className="fw-bolder">{t('What can you generate?')}</h1>
                                    <p className="fs-5 fw-light">{t('Our AI has been trained by knowledgeable content writers and conversion specialists, ensuring top-notch performance when generating content for your website or social media.')}</p><a href="/sign-up"
                                                                                                   className="btn btn-lg btn-primary mt-4">{t('Start Free Trial')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 mt-lg-0 mt-5">
                            <div className="section_our_solution">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="our_solution_category">
                                            <div className="solution_cards_box">
                                                <div className="solution_card p-4">
                                                    <div className="d-flex align-items-center gap-2 mb-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--ic" width="40" height="40" viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                                            <path fill="currentColor"
                                                                  d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"></path>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--icon-park-outline" width="40"
                                                             height="40" viewBox="0 0 48 48">
                                                            <g fill="none" stroke="currentColor" stroke-linecap="round"
                                                               stroke-linejoin="round" stroke-width="4">
                                                                <path d="M24 9h18M24 19h18M6 29h36M6 39h36"></path>
                                                                <circle cx="12" cy="14" r="5"></circle>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="solu_title">
                                                        <h3>{t('Blog Content')}</h3>
                                                    </div>
                                                    <div className="solu_description">
                                                        <p className="mb-0 fs-5 fw-light">{t('Blog articles are an important part of any website when it comes to generate organic traffic for your business.')}</p>
                                                        <button type="button" className="btn btn-link p-0 my-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 aria-hidden="true" role="img"
                                                                 className="text-white iconify iconify--material-symbols"
                                                                 width="32" height="32" viewBox="0 0 24 24">
                                                                <path fill="currentColor"
                                                                      d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="solution_card p-4">
                                                    <div className="d-flex align-items-center gap-2 mb-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--icon-park-outline" width="40"
                                                             height="40" viewBox="0 0 48 48">
                                                            <g fill="none" stroke="currentColor" stroke-width="4">
                                                                <rect width="40" height="32" x="4" y="8"
                                                                      rx="1.633"></rect>
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                      d="M16 18.948c-2-2.948-5.502-1.01-5.251 2.02C11 24 15 24 15.249 27.032C15.5 30.062 12 32 10 29.051M26 18h-4v13h4m-4-6h4"></path>
                                                                <rect width="6" height="13" x="32" y="18"
                                                                      stroke-linecap="round" stroke-linejoin="round"
                                                                      rx="3"></rect>
                                                            </g>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--mdi" width="40" height="40"
                                                             viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14v14m-8-3h-1c-1.61 0-4-1.06-4-4c0-2.93 2.39-4 4-4h1v2h-1c-.46 0-2 .17-2 2c0 1.9 1.67 2 2 2h1v2m3 0h-1v-2h1c.46 0 2-.17 2-2c0-1.9-1.67-2-2-2h-1V8h1c1.61 0 4 1.07 4 4c0 2.94-2.39 4-4 4m1-3H9v-2h6v2Z"></path>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--mdi" width="40" height="40"
                                                             viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14v14m-8-3h-1c-1.61 0-4-1.06-4-4c0-2.93 2.39-4 4-4h1v2h-1c-.46 0-2 .17-2 2c0 1.9 1.67 2 2 2h1v2m3 0h-1v-2h1c.46 0 2-.17 2-2c0-1.9-1.67-2-2-2h-1V8h1c1.61 0 4 1.07 4 4c0 2.94-2.39 4-4 4m1-3H9v-2h6v2Z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="solu_title">
                                                        <h3>{t('Social Media & Ads')}</h3>
                                                    </div>
                                                    <div className="solu_description">
                                                        <p className="mb-0 fs-5 fw-light">{t('Write Facebook or Google ads, Youtube video descriptions or titles')}</p>
                                                        <button type="button" className="btn btn-link p-0 my-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 aria-hidden="true" role="img"
                                                                 className="text-white iconify iconify--material-symbols"
                                                                 width="32" height="32" viewBox="0 0 24 24">
                                                                <path fill="currentColor"
                                                                      d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="solution_cards_box sol_card_top_3">
                                                <div className="solution_card p-4">
                                                    <div className="d-flex align-items-center gap-2 mb-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--lucide" width="40" height="40"
                                                             viewBox="0 0 24 24">
                                                            <g fill="none" stroke="currentColor" stroke-linecap="round"
                                                               stroke-linejoin="round" stroke-width="2">
                                                                <rect width="20" height="20" x="2" y="2" rx="5"
                                                                      ry="5"></rect>
                                                                <path
                                                                    d="M16 11.37A4 4 0 1 1 12.63 8A4 4 0 0 1 16 11.37zm1.5-4.87h.01"></path>
                                                            </g>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--jam" width="40" height="40"
                                                             viewBox="-2 -2 24 24">
                                                            <g fill="currentColor">
                                                                <path
                                                                    d="M8.695 6.937v1.377H7.687v1.683h1.008V15h2.072V9.997h1.39s.131-.807.194-1.69h-1.576v-1.15c0-.173.226-.404.45-.404h1.128V5h-1.535C8.644 5 8.695 6.685 8.695 6.937z"></path>
                                                                <path
                                                                    d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"></path>
                                                            </g>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--ri" width="40" height="40"
                                                             viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M19.606 6.995c-.076-.298-.292-.523-.539-.592C18.63 6.28 16.5 6 12 6s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C4.285 7.419 4 9.196 4 12s.285 4.58.394 5.006c.076.297.292.522.538.59C5.372 17.72 7.5 18 12 18s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592C19.715 16.581 20 14.8 20 12s-.285-4.58-.394-5.005zm1.937-.497C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5v-7l6 3.5l-6 3.5z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="solu_title">
                                                        <h3>{t('Website Copy & SEO')}</h3>
                                                    </div>
                                                    <div className="solu_description">
                                                        <p className="mb-0 fs-5 fw-light">{t('Missing inspiration for your Landing Page? Generate headlines, subheadlines or meta tags.')}</p>
                                                        <button type="button" className="btn btn-link p-0 my-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 aria-hidden="true" role="img"
                                                                 className="text-white iconify iconify--material-symbols"
                                                                 width="32" height="32" viewBox="0 0 24 24">
                                                                <path fill="currentColor"
                                                                      d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="solution_card p-4">
                                                    <div className="d-flex align-items-center gap-2 mb-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             aria-hidden="true" role="img"
                                                             className="iconify iconify--uil" width="40" height="40"
                                                             viewBox="0 0 24 24">
                                                            <path fill="currentColor"
                                                                  d="M22 5H2a1 1 0 0 0-1 1v4a3 3 0 0 0 2 2.82V22a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-9.18A3 3 0 0 0 23 10V6a1 1 0 0 0-1-1Zm-7 2h2v3a1 1 0 0 1-2 0Zm-4 0h2v3a1 1 0 0 1-2 0ZM7 7h2v3a1 1 0 0 1-2 0Zm-3 4a1 1 0 0 1-1-1V7h2v3a1 1 0 0 1-1 1Zm10 10h-4v-2a2 2 0 0 1 4 0Zm5 0h-3v-2a4 4 0 0 0-8 0v2H5v-8.18a3.17 3.17 0 0 0 1-.6a3 3 0 0 0 4 0a3 3 0 0 0 4 0a3 3 0 0 0 4 0a3.17 3.17 0 0 0 1 .6Zm2-11a1 1 0 0 1-2 0V7h2ZM4.3 3H20a1 1 0 0 0 0-2H4.3a1 1 0 0 0 0 2Z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="solu_title">
                                                        <h3>{t('eCommerce Copy')}</h3>
                                                    </div>
                                                    <div className="solu_description">
                                                        <p className="mb-0 fs-5 fw-light">{t('Finding Product Names or Product Descriptions can be very time consuming. Leave it to Us so you can focus on your store.')}</p>
                                                        <button type="button" className="btn btn-link p-0 my-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 aria-hidden="true" role="img"
                                                                 className="text-white iconify iconify--material-symbols"
                                                                 width="32" height="32" viewBox="0 0 24 24">
                                                                <path fill="currentColor"
                                                                      d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="features" className="mt-5 mb-5 py-lg-5 py-0">
                        <div className="text-center mt-5 mb-5">
                            <p className="text-uppercase text-primary fw-bold mb-0">{t('The pinnacle of AI language models')}</p>
                            <h1 className="fw-bolder">{t('Generate unique, human-like copy in a matter of seconds')}</h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--fluent" width="40"
                                                 height="40" viewBox="0 0 20 20">
                                                <path fill="currentColor"
                                                      d="M6.13 2.793A3.91 3.91 0 0 1 8.5 2c.24 0 .431.125.542.211c.124.098.24.223.344.348a.5.5 0 0 1 .114.318V6.5H8.415a1.5 1.5 0 1 0 0 1H9.5v9.415a.5.5 0 0 1-.183.387C8.855 17.68 8.237 18 7.5 18c-1.186 0-2.069-.598-2.64-1.313a4.057 4.057 0 0 1-.819-1.752a2.68 2.68 0 0 1-1.054-.555C2.435 13.907 2 13.14 2 12c0-.557.037-1.06.12-1.5h3.13c.636 0 1.16.475 1.24 1.089a1.5 1.5 0 1 0 1.004-.006A2.25 2.25 0 0 0 5.25 9.5H2.441c.153-.298.358-.55.625-.729a1.58 1.58 0 0 1 .212-.118c-.284-.832-.21-1.806.064-2.571c.175-.492.453-.957.835-1.267c.252-.205.552-.34.88-.366c.144-.683.549-1.248 1.074-1.656ZM10.5 14.5h.75a2.25 2.25 0 0 0 2.25-2.25v-1.835a1.5 1.5 0 1 0-1 0v1.835c0 .69-.56 1.25-1.25 1.25h-.75V2.877a.5.5 0 0 1 .114-.318c.103-.125.22-.25.344-.348c.11-.086.301-.211.542-.211a3.91 3.91 0 0 1 2.37.793c.525.408.93.973 1.073 1.656c.328.025.628.161.88.366c.382.31.66.775.835 1.267c.274.765.348 1.74.064 2.57c.072.034.143.074.212.12c.275.183.484.445.638.754c.303.605.428 1.449.428 2.474c0 1.141-.435 1.907-.987 2.38a2.68 2.68 0 0 1-1.054.555c-.1.558-.38 1.204-.819 1.752c-.57.715-1.454 1.313-2.64 1.313c-.736 0-1.355-.32-1.816-.698a.5.5 0 0 1-.184-.387V14.5ZM7 6.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1Zm0 6a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1Zm6-3a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1Z"></path>
                                            </svg>
                                        </div>
                                        <h3>{t('Powered by AI')}</h3>
                                        <p>{t('GPT-3 sets a new standard in AI language models with its natural, one-of-a-kind, and imaginative output.')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--ic" width="40"
                                                 height="40" viewBox="0 0 24 24">
                                                <path fill="currentColor"
                                                      d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 14H4V5h16v12z"></path>
                                                <path fill="currentColor"
                                                      d="M6 8.25h8v1.5H6zm10.5 1.5H18v-1.5h-1.5V7H15v4h1.5zm-6.5 2.5h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"></path>
                                            </svg>
                                        </div>
                                        <h3>{t('Powerful settings')}</h3>
                                        <p>{t('Fine-tune the level of creativity and tone of voice to produce the ideal copy for your business needs.')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--fluent" width="40"
                                                 height="40" viewBox="0 0 24 24">
                                                <path fill="currentColor"
                                                      d="M19.25 18.5h2a.75.75 0 0 1 .102 1.493L21.25 20H20v1.25a.75.75 0 0 1-1.493.102l-.007-.102v-2a.75.75 0 0 1 .648-.743l.102-.007h2h-2Zm-16.5 0h2a.75.75 0 0 1 .743.648l.007.102v2a.75.75 0 0 1-1.493.102L4 21.25V20H2.75a.75.75 0 0 1-.102-1.493l.102-.007h2h-2Zm14-12.997a.75.75 0 0 1 .75.75V17.75a.75.75 0 0 1-.75.75H7.252a.75.75 0 0 1-.75-.75V6.253a.75.75 0 0 1 .75-.75h9.5Zm-.75 1.5H8.002V17h8V7.003Zm-6.25 5.511h2.502a.75.75 0 0 1 .102 1.493l-.102.007H9.75a.75.75 0 0 1-.102-1.493l.102-.007h2.502H9.75Zm0-3.014h4.502a.75.75 0 0 1 .102 1.493l-.102.007H9.75a.75.75 0 0 1-.102-1.493L9.75 9.5h4.502H9.75Zm-5-7.5a.75.75 0 0 1 .743.648l.007.102v2a.75.75 0 0 1-.648.743L4.75 5.5h-2a.75.75 0 0 1-.102-1.493L2.75 4H4V2.75A.75.75 0 0 1 4.75 2Zm14.5 0a.75.75 0 0 1 .743.648L20 2.75V4h1.25a.75.75 0 0 1 .102 1.493l-.102.007h-2a.75.75 0 0 1-.743-.648L18.5 4.75v-2a.75.75 0 0 1 .75-.75Z"></path>
                                            </svg>
                                        </div>
                                        <h3>{t('Optimized for conversions')}</h3>
                                        <p>{t('Designed with a focus on conversions, writes content that grabs attention and drives results.')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--tabler" width="40"
                                                 height="40" viewBox="0 0 24 24">
                                                <g fill="none" stroke="currentColor" stroke-linecap="round"
                                                   stroke-linejoin="round" stroke-width="2">
                                                    <path
                                                        d="m16 12l4-4a2.828 2.828 0 1 0-4-4l-4 4m-2 2l-7 7v4h4l7-7m.5-8.5l4 4"></path>
                                                    <path
                                                        d="M12 8L7 3M5 5L3 7l5 5M7 8L5.5 9.5M16 12l5 5m-2 2l-2 2l-5-5m4 1l-1.5 1.5M3 3l18 18"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <h3>{t('50+ Available Tools')}</h3>
                                        <p>{t('Generate all types of copy or content in seconds with the ultimate creative writing tool.')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--fluent" width="40"
                                                 height="40" viewBox="0 0 24 24">
                                                <path fill="currentColor"
                                                      d="M3 17h7.522l-2 2H3a1 1 0 0 1-.117-1.993L3 17Zm0-2h7.848a1.75 1.75 0 0 1-.775-2H3l-.117.007A1 1 0 0 0 3 15Zm0-8h18l.117-.007A1 1 0 0 0 21 5H3l-.117.007A1 1 0 0 0 3 7Zm9.72 9.216a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l4.5-4.5ZM3 9h10a1 1 0 0 1 .117 1.993L13 11H3a1 1 0 0 1-.117-1.993L3 9Zm13.5-1a.75.75 0 0 1 .744.658l.14 1.13a3.25 3.25 0 0 0 2.828 2.829l1.13.139a.75.75 0 0 1 0 1.488l-1.13.14a3.25 3.25 0 0 0-2.829 2.828l-.139 1.13a.75.75 0 0 1-1.488 0l-.14-1.13a3.25 3.25 0 0 0-2.828-2.829l-1.13-.139a.75.75 0 0 1 0-1.488l1.13-.14a3.25 3.25 0 0 0 2.829-2.828l.139-1.13A.75.75 0 0 1 16.5 8Z"></path>
                                            </svg>
                                        </div>
                                        <h3>{t('Grammar check')}</h3>
                                        <p>{t('Dont let poor grammar hurt your visitors trust, we can check and rewrite your content.')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-4">
                                <div className="px-2">
                                    <div
                                        className="card border-0 shadow h-100 p-3 d-flex justify-content-center text-center align-items-center">
                                        <div
                                            className="px-3 py-2 bg-light bg-gradient rounded-4 d-flex justify-content-center align-items-center mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 role="img" className="text-primary iconify iconify--carbon" width="40"
                                                 height="40" viewBox="0 0 32 32">
                                                <path fill="currentColor"
                                                      d="M29 23h-5a2.003 2.003 0 0 1-2-2v-6a2.002 2.002 0 0 1 2-2h5v2h-5v6h5zM18 13h-4V9h-2v14h6a2.003 2.003 0 0 0 2-2v-6a2.002 2.002 0 0 0-2-2zm-4 8v-6h4v6zM8 9H4a2.002 2.002 0 0 0-2 2v12h2v-5h4v5h2V11a2.002 2.002 0 0 0-2-2zm-4 7v-5h4v5z"></path>
                                            </svg>
                                        </div>
                                        <h3>{t('Sentence rewriter')}</h3>
                                        <p>{t('AI understands your sentence and rewrites it in a completely unique and smart way.')}'</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div id="pricing" className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="fw-bolder">{t('Pricing')}</h1>
                    </div>*/}

                </main>
            </div>
            <footer className="footer-color py-lg-5 pt-5 pb-3 bg-dark border-top">
                <div className="container py-2">
                    <div className="px-2">
                        <div className="row">
                            <div className="col-12 col-md">
                                <div className="mb-2">
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/`);
                                            e.preventDefault();
                                        }}
                                        href={`/`}
                                        className="d-flex align-items-center text-decoration-none text-white">
                                        <img width="120" height="40" src="/assets/images/Logo232.svg"/>
                                    </a>
                                </div>
                                <small className="d-block mt-4 mb-4" style={{maxWidth: 300}}>{t('company_description')}</small>
                            </div>
                            <div className="col-lg-6 col-12 px-lg-5 px-3">
                                <div className="row mt-lg-0 mt-4">
                                    <div className="col-6 col-md">
                                        <h5 className="text-white mb-4">{t('About')}</h5>
                                        <ul className="list-unstyled text-small">
                                            <li className="mb-2"><a className="link-footer text-decoration-none" href="/#pricing">{t('Pricing')}</a></li>
                                            <li className="mb-2">
                                                <a
                                                    onClick={(e) => {
                                                        linkTo(`/sign-in`);
                                                        e.preventDefault();
                                                    }}
                                                    href={`sign-in`}
                                                    className="link-footer text-decoration-none">{t('Login')}</a>
                                            </li>
                                            <li className="mb-2">
                                                <a
                                                    onClick={(e) => {
                                                        linkTo(`/sign-up`);
                                                        e.preventDefault();
                                                    }}
                                                    href={`sign-up`}
                                                    className="link-footer text-decoration-none">{t('Sign Up')}</a></li>
                                            <li className="mb-2">
                                                <a
                                                    onClick={(e) => {
                                                        linkTo(`/blog`);
                                                        e.preventDefault();
                                                    }}
                                                    href={`/blog`}
                                                    className="link-footer text-decoration-none">{t('Blog')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md">
                                        <h5 className="text-white mb-4">{t('Information')}</h5>
                                        <ul className="list-unstyled text-small">
                                            {pages.map((item, index) => (
                                                <li  key={index} className="mb-2">
                                                    <a
                                                        onClick={(e) => {
                                                            linkTo(`/page/${item.alt_name}`);
                                                            e.preventDefault();}
                                                        }
                                                        href={`/page/${item.alt_name}`}
                                                        className="link-footer text-decoration-none"
                                                    >{item.title}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-5">
                            <div>
                                <small className="d-block">{t('© 2023. All licenses belong to AI Engine.')}</small>
                            </div>
                            <div
                                className="d-flex gap-2"
                            >
                                {[
                                    {
                                        link: t('facebook_page'),
                                        icon: 'ri:facebook-fill'
                                    },
                                    {
                                        link: t('twitter_page'),
                                        icon: 'teenyicons:twitter-solid'
                                    },
                                    {
                                        link: t('linkein_page'),
                                        icon: 'akar-icons:linkedin-v2-fill'
                                    },
                                    {
                                        link: t('tiktok_page'),
                                        icon: 'icon-park-solid:tiktok'
                                    },
                                    {
                                        link: t('instagram_page'),
                                        icon: 'ant-design:instagram-filled'
                                    }
                                ].map((item, index) => (
                                    <a
                                        key={index}
                                        href={item.link}
                                        target="_blank"
                                        className="w-40px rounded-circle bg-secondary d-flex justify-content-center align-items-center text-white">
                                        <Icon icon={item.icon} />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default connect(({auth, display}) => ({auth, display}))(IndexPage);
