import React, {useEffect, useRef , useState} from "react";
import {connect} from "react-redux";
import {t} from "i18next";
import ScrollToBottom from 'react-scroll-to-bottom';

import API from "../../helpers/Axios";
import UserAvatar from "../../components/UserAvatar";



const ChatPage = ({auth, common}) => {

    const [contentWithTools, setContentWithTools] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [value, setValue] = useState('');
    const textarea = document.getElementById('textarea');

    const [workBot, setWorkBot] = useState(false);
    const [disableSend, setDisableSend] = useState(false);





    function renderItem(item, index) {
        if(item.type === "user") {
            return (
                <div key={index} className="message_chat message_users">
                    <div className="message_block_user">
                        <span>{item.text}</span>
                    </div>
                    <UserAvatar
                        url={auth.user.artwork_url}
                        width="40"
                    />
                </div>
            )
        }else {
            return (
                <div key={index} className="message_chat message_bots">
                    <img src="/assets/images/svg/Avatar_bot.svg" width="40px"/>
                    <div className="message_block_bot">
                        <span>{item.text}</span>
                    </div>
                </div>
            )
        }
    }


    const getContents = () => {
        API.post('auth/chat/get').then(res => {
            const filteredResponse = res.data.data;

            const resultArray = filteredResponse.map(item => {
                const id = item.id;
                const type = item.type;
                const text = item.text;
                return { id, type, text };
            });

            const grouped = resultArray.reduce((acc, cur) => {
                const groupKey = cur.groupTitle;
                const group = acc.find(group => group.groupTitle === groupKey);
                if (group) {
                    group.items.push(cur);
                } else {
                    acc.push({ groupTitle: groupKey, items: [cur] });
                }
                return acc;
            }, []);

            setContentWithTools(grouped);
            setIsLoading(false);
        }).catch (error => {

        });
    }

    function setupText(arrayText) {
        const resultArray = arrayText[0].items.map(item => {
            const id = item.id;
            const type = item.type;
            const text = item.text;
            return { id, type, text };
        });

        const grouped = resultArray.reduce((acc, cur) => {
            const groupKey = cur.groupTitle;
            const group = acc.find(group => group.groupTitle === groupKey);
            if (group) {
                group.items.push(cur);
            } else {
                acc.push({ groupTitle: groupKey, items: [cur] });
            }
            return acc;
        }, []);

        setContentWithTools(grouped);
    }

    const addText = (value) => {

        setWorkBot(t('🤖 The bot prints a message...'));
        setDisableSend(true);

        const newArray = "";
        const copy = "";

        if(contentWithTools.length) {
            const newArray = { id: contentWithTools[0].items.length + 1, text: value, type: "user" };
            const copy = contentWithTools[0].items.push(newArray);
            setupText(contentWithTools);
        }else {
            const contentWithTools = [{
                items: []
            }];
            const newArray = { id: 0, text: value, type: "user" };
            const copy = contentWithTools[0].items.push(newArray);
            setupText(contentWithTools);
        }


        API.post('auth/chat/send', {
            text: value
        }).then(res => {
            getContents()
            setWorkBot(false);
            setDisableSend(false);
        }).catch(error => {

        });
    }

    useEffect(() => {
        setIsLoading(true);
        getContents();
    }, [common.currentProject]);

    function textChange(event) {
        setValue(event.target.value);
    }
    function sendText() {
        if(value) {
            addText(value);
            textarea.value = "";
            setValue('');
        }
    }


    return isLoading ? (<div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >{t('Loading...')}</div>) : (
        <>
           <div className="chat_block_new">

               <div className="chat_block_botInfo">
                    <div className="bot_info_block">
                        <img src="/assets/images/svg/Avatar_bot.svg" width="60px"/>
                        <div className="bot_info_block_dotted">●</div>
                        <div className="bot_info_block_titles">
                            <span className="bot_info_block_titles_top">Weeksy</span><br />
                            <span className="bot_info_block_titles_bottom">AI Chat</span>
                        </div>
                    </div>
               </div>
               <hr className="bot_info_hr" />
               <div className="row mt-3 mx-lg-0 mx-1">
                   <ScrollToBottom className="d-block justify-content-between align-items-center chat_block">
                       {contentWithTools.map((group, index) => group.items.map((item, index) => renderItem(item, index)))}

                       {workBot}
                   </ScrollToBottom>
               </div>

               <div className="bot_bottom_div">
                   <div className="bot_bottom_div_input">
                       <textarea
                           className="w-100 bg-white chat_textarea_active"
                           type="text" autoComplete="off" spellCheck="false"
                           placeholder={t('Type your message...')}
                           required=""
                           minLength="1" maxLength="1500"
                           id="textarea"
                           data-gtm-form-interact-field-id="2"
                           onChange={textChange}
                       ></textarea>
                       <button
                           onClick={(e) => {
                               e.preventDefault();
                               sendText();
                           }}
                           disabled={disableSend}

                           className="btn btn_to_bot">{t('Send to chat')}</button>
                   </div>
               </div>

           </div>


        </>
    )
}

export default connect(({auth, common}) => ({auth, common}))(React.memo(ChatPage));

